import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App/index";
import Maintenance from "./Maintenance";
import * as serviceWorker from "./serviceWorker";
import config from "./config";
import i18n from "i18next";
import { initReactI18next, I18nextProvider } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./store/languages/en/translation.json";
import translationDE from "./store/languages/de/translation.json";
import store from "./redux/Store";
import { ToastContainer } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';

const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: "en",
    lng: localStorage.getItem("lng") || "en",
    saveMissing: true,
    interpolation: {
      escapeValue: false,
    },
  });

const maintenance_mode = process.env.REACT_APP_MAINTENANCE_MODE;

if (maintenance_mode === true || maintenance_mode === "true") {
  ReactDOM.render(<Maintenance />, document.getElementById("root"));
} else {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter basename={config.basename}>
        {/* basename="/datta-able" */}
        <I18nextProvider i18n={i18n}>
          <ToastContainer />
          <App />
        </I18nextProvider>
      </BrowserRouter>
    </Provider>,
    document.getElementById("root")
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
